import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0089d1',
    },
    secondary: {
      main: '#393E46',
    },
    black: {
      main: '#001c3c',
    },
    white: {
      main: '#EEEEEE',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          textTransform: 'capitalize !important',
          fontSize: '14px !important',
          lineHeight: '1 !important',
          fontWeight: '500',
          color: '#fff',
          padding: '11px 20px',
          width: 'fit-content',
          minWidth: 'unset',
          whiteSpace: 'nowrap',
          boxShadow: 'unset',
          '@media (max-width: 1200px)': {
            fontSize: '12px !important',
            padding: '9px 20px',
          },
          '& .MuiButton-startIcon svg': {
            '@media (max-width: 600px)': {
              fontSize: '12px !important',
            },
          }
        },
        containedWhite: {
          color: 'rgba(0, 0, 0)',
          boxShadow: 'unset !important',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        sizeSmall: {
          padding: '12px 14px',
          fontSize: '14px !important',
        },
        containedGreen: {
          padding: '10px 20px',
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '7px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            input: {
              fontSize: '14px',
              fontWeight: '400',
              padding: '10px 15px',
              color: '#0a0a08',
              // background: '#fff',
              '@media (max-width: 1200px)': {
                fontSize: '12px !important',
                padding: '7px 12px',
              },
            },
            '& input[type=number]': {
              'MozAppearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          },
            textarea: {
              color: '#eeeeee',
            },
            fieldset: {
              border: '2px solid #0089d1 !important',
              borderRadius: '6px',
            }
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#747474',
          },
          '& .MuiInputBase-adornedStart ': {
            paddingLeft: '10px',
            input: {
              padding: '9px 14px 9px 0',
            },
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '6px 15px !important',
        },
        popper: {
          zIndex: 99999,
        },
        inputRoot: {
          padding: '5px',
        },
        root: {
          '& .MuiInputBase-input': {
            boxShadow: 'unset !important',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: '10px',
          fontSize: '16px',
          fontWeight: '400',
          padding: '11px 20px',
          minHeight: 'unset',
          color: '#000',
          background: '#fff',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            padding: '10px 15px',
          },
          fieldset: {
            border: '1px solid rgba(0, 0, 0, 0.12)'
          },
          '& .MuiInputBase-root:hover': {
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#000',
          },
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '@media (max-width: 600px)': {
            fontSize: '14px !important',
            minHeight: '35px !important',
          },
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          borderRadius: '12px',
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
              textTransform: 'capitalize',
              color: '#0F0F0F70'
            }
          },
          '& .MuiTableCell-root': {
            padding: '14px 16px',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            color: '#0F0F0F',
            textAlign: 'center !important',
            // '&:nth-of-type(2)': {
            //   textAlign: 'start !important',
            // },
            '&:first-of-type': {
              textAlign: 'start !important',
            },
            '&:last-child': {
              textAlign: 'end !important',
            },
          },
          // Media query for smaller screens
          '@media (max-width: 600px)': {
            '& .MuiTableCell-root': {
              fontSize: '14px',
              padding: '10px 10px',

            },
          },
          '& .MuiTableBody-root': {
            '& .MuiTableRow-root:hover': {
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              background: '#7345B310'
            },
          },
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          height: '28px',
          fontSize: '16px',
          '@media (max-width: 600px)': {
            height: '24px',
            fontSize: '14px',
          },
          '& .MuiSvgIcon-root ': {
            fontSize: '16px',
          }
        }
      }
    },
    // table -----------
    // modal -----------
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '16px !important',
          fontWeight: '600',
          color: '#0089d1'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 16px'
        }
      }
    },
    // modal -----------
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: '40px',
            textTransform: 'capitalize',
            alignItems: 'flex-start'
          },
          '& .Mui-selected ': {
            background: '#7345B320',
          },
          '& .MuiTabs-indicator': {
            left: 0,
            right: 'unset',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '9999',
        },
        paper: {
          // backgroundColor: '#222831',
          // color: '#fff',
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '20px !important'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: 0,
        }
      }
    },
  },
});

// export const MyComponent = styled('div')({
//   borderRadius: '0px',
//   background: '#151515',
//   boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
// });

export const getEducatorsStatus = (value) => {
  const status = {
    false: {
      title: "Absent",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "Present ",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};

export const getAlertStatus = (value) => {
  const status = {
    false: {
      title: "Off",
      styles: {
        backgroundColor: "#FF563625",
        color: "#FF5636",
      },
    },
    true: {
      title: "On",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.20)",
        color: "#00AD6F",
      },
    },

  };
  return status[value];
};